
import { Component, Vue } from "vue-property-decorator";
import {MutationPayload} from "vuex";
import {SupplyActions} from "@/store/modules/supply/actions";
import {ConnectorMutations} from "@/store/modules/connector/mutations";

@Component({
  components: {},
  computed: {
    supplyWallet: function (): string {
      return this.$store.state.supply.supplyWallet;
    },
  },
})
export default class SupplyDisplay extends Vue {
  unsubscribe: () => void = () => null;
  mounted(): void {
    if (this.$store.state.connector.account) {
      this.$store.dispatch(SupplyActions.GET_SUPPLY_WALLET);
    }
    this.unsubscribe = this.$store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === ConnectorMutations.SET_ACCOUNT && mutation.payload) {
        this.$store.dispatch(SupplyActions.GET_SUPPLY_WALLET);
      }
    });
  }
  beforeDestroy(): void {
    this.unsubscribe();
  }
}
