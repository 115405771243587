
import Vue from "vue";
import Component from "vue-class-component";
import RaffleTile from "@/components/RaffleTile.vue";
import {Prop} from "vue-property-decorator";
import {Raffle} from "@/types/Interface";

@Component({
  components: {RaffleTile}
})
export default class RaffleTable extends Vue {
  @Prop() raffles!: Raffle[];
  filter = "all";
  search = "";

  selectFilter(filter: string): void {
    this.filter = filter;
    const elements = document.querySelectorAll("#RaffleTable .filter");
    const selected = document.getElementById(filter + "_raffle_table");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("selected");
    }
    selected?.classList.add("selected");
  }
}
