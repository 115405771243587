
import {Component, Prop, Vue} from "vue-property-decorator";
import {Raffle} from "@/types/Interface";

@Component({
  computed: {
    account(): string {
      return this.$store.state.connector.account;
    },
  }
})
export default class BuyModal extends Vue {
  @Prop() raffle!: Raffle;
  @Prop() price!: number;
  @Prop() selected!: string;
  @Prop() buyFunction!: () => Promise<void>;
  @Prop() tickets!: number;

  buy(): void {
    this.closeModal();
    this.buyFunction();
  }

  closeModal(): void {
    this.$modal.hide('buy_' + this.raffle.id);
  }
}
