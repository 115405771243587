
import { Component, Vue } from "vue-property-decorator";
import Connect from "@/components/Connect.vue";

@Component({
  components: {Connect},
  computed: {
    account: function () {
      return this.$store.state.connector.account;
    },
  },
})
export default class Home extends Vue {
  async start(): Promise<void> {
    await this.$router.push("dashboard/knives/raffle");
  }
}
