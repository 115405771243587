
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import {MutationPayload} from "vuex";
import {Knife} from "@/types/Interface";
import Connect from "@/components/Connect.vue";
import SupplyDisplay from "@/components/SupplyDisplay.vue";
import {ApproveActions} from "@/store/modules/approve/actions";
import {KnifeActions} from "@/store/modules/knife/actions";
import {StakingKnifeActions} from "@/store/modules/stakingKnife/actions";
import {ConnectorMutations} from "@/store/modules/connector/mutations";
import {LoaderMutation} from "@/store/modules/loader/mutations";

@Component({
  components: {SupplyDisplay, Connect, Loader},
  computed: {
    knives(): Knife[] {
      return this.$store.state.knife.knives;
    },
    loading(): boolean {
      return this.$store.state.loader.loading;
    },
    knivesStakingContractApproved(): boolean {
      return this.$store.state.approve.knivesStakingContractApproved;
    },
    stakedKnives(): Knife[] {
      return this.$store.state.stakingKnife.stakedKnives;
    },
    claimableSupply(): string {
      return this.$store.state.stakingKnife.claimableSupply;
    },
    account: function () {
      return this.$store.state.connector.account;
    },
  }
})
export default class KnivesStaking extends Vue {
  unsubscribe: () => void = () => null;
  selectedKnife: Knife[] = [];
  stakedSelectedKnife: Knife[] = [];
  mounted(): void {
    if (this.$store.state.connector.account) {
      this.$store.dispatch(ApproveActions.GET_KNIVES_STAKING_CONTRACT_APPROVAL);
      this.$store.dispatch(KnifeActions.GET_KNIFE);
      this.$store.dispatch(StakingKnifeActions.GET_STAKING_KNIVES);
      this.$store.dispatch(StakingKnifeActions.GET_CLAIMABLE_SUPPLY);
    }
    this.unsubscribe = this.$store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === ConnectorMutations.SET_ACCOUNT && mutation.payload) {
        this.$store.dispatch(ApproveActions.GET_KNIVES_STAKING_CONTRACT_APPROVAL);
        this.$store.dispatch(KnifeActions.GET_KNIFE);
        this.$store.dispatch(StakingKnifeActions.GET_STAKING_KNIVES);
        this.$store.dispatch(StakingKnifeActions.GET_CLAIMABLE_SUPPLY);
      }
    });
  }

  beforeDestroy(): void {
    this.unsubscribe();
  }

  async triggerApprove(): Promise<void> {
    this.$store.commit(LoaderMutation.SET_LOADING, true);
    await this.$store.dispatch(ApproveActions.APPROVE_KNIVES_STAKING_CONTRACT);
  }

  async triggerDeposit(): Promise<void> {
    this.$store.commit(LoaderMutation.SET_LOADING, true);
    await this.$store.dispatch(StakingKnifeActions.DEPOSIT_ALL_KNIVES, this.selectedKnife);
    this.selectedKnife = [];
  }

  async triggerWithdraw(): Promise<void> {
    this.$store.commit(LoaderMutation.SET_LOADING, true);
    await this.$store.dispatch(StakingKnifeActions.WITHDRAW_ALL_KNIVES, this.stakedSelectedKnife);
    this.stakedSelectedKnife = [];
  }

  async triggerClaim(): Promise<void> {
    this.$store.commit(LoaderMutation.SET_LOADING, true);
    await this.$store.dispatch(StakingKnifeActions.CLAIM_SUPPLY);
  }

  selectKnife(knife: Knife): void {
    if (this.selectedKnife.includes(knife)) {
      this.selectedKnife = this.selectedKnife.filter((k) => k.id !== knife.id);
    } else {
      this.selectedKnife.push(knife);
    }
  }

  selectStakedKnife(knife: Knife): void {
    if (this.stakedSelectedKnife.includes(knife)) {
      this.stakedSelectedKnife = this.stakedSelectedKnife.filter((k) => k.id !== knife.id);
    } else {
      this.stakedSelectedKnife.push(knife);
    }
  }
}
