
import { Component, Vue } from "vue-property-decorator";
import Home from "@/components/Home.vue";
import {ConnectorActions} from "@/store/modules/connector/actions";
import ConnectModal from "@/components/ConnectModal.vue";
@Component({
  components: {
    Home,
    ConnectModal,
  },
})
export default class App extends Vue {
  mounted(): void {
    this.$store.dispatch(ConnectorActions.GET_FROM_LOCAL_STORAGE);
  }
}
