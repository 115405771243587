
import { Component, Vue } from "vue-property-decorator";
import {ConnectorActions} from "@/store/modules/connector/actions";

@Component({
  computed: {
    account: function () {
      return this.$store.state.connector.account;
    },
  },
})
export default class Connect extends Vue {
  connect(): void {
    this.$modal.show("connect");
  }

  disconnect(): void {
    this.$store.dispatch(ConnectorActions.DISCONNECT);
  }

  truncate(fullStr: string, strLen: number): string {
    if (fullStr.length <= strLen) return fullStr;
    const separator = '...';
    const sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow/2),
        backChars = Math.floor(charsToShow/2);
    return fullStr.substring(0, frontChars) +
        separator +
        fullStr.substring(fullStr.length - backChars);
  }
}
