
import Vue from "vue";
import Component from "vue-class-component";
import RaffleTile from "@/components/RaffleTile.vue";
import {Prop} from "vue-property-decorator";
import {Raffle} from "@/types/Interface";

@Component({
  components: {RaffleTile},
  computed: {
    account(): string {
      return this.$store.state.connector.account;
    }
  }
})
export default class RaffleTable extends Vue {
  @Prop() raffles!: Raffle[];
  filter = "all";
  search = "";
  winStatus: string[] = [];

  mounted(): void {
    this.selectFilter("all");
    for (const raffle of this.raffles) {
      if (raffle.status === "close" && raffle.winners.length > 0) {
        const won = raffle.winners.some((winner) => winner.toLowerCase() === this.$store.state.connector.account.toLowerCase());
        if (won) {
          this.winStatus.push("WIN");
        } else {
          this.winStatus.push("LOSE");
        }
      } else {
        this.winStatus.push(
          "-"
        );
      }
    }
  }

  selectFilter(filter: string): void {
    this.filter = filter;
    const elements = document.querySelectorAll("#TicketTable .filter");
    const selected = document.getElementById(filter + "_ticket_table");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("selected");
    }
    selected?.classList.add("selected");
  }
}
