
import {Component, Prop, Vue} from "vue-property-decorator";
import {Raffle} from "@/types/Interface";

@Component({
  computed: {
    account(): string {
      return this.$store.state.connector.account;
    },
  }
})
export default class WinnerModal extends Vue {
  @Prop() raffle!: Raffle;
  @Prop() won!: boolean;

  truncate(fullStr: string, strLen: number): string {
    if (fullStr.length <= strLen) return fullStr;
    const separator = '.....';
    const sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow/2),
        backChars = Math.floor(charsToShow/2);
    return fullStr.substring(0, frontChars) +
        separator +
        fullStr.substring(fullStr.length - backChars);
  }

  closeModal(): void {
    this.$modal.hide('winner_' + this.raffle.id);
  }
}
