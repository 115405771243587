
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class NavBar extends Vue {
  sound = false;

  muteSound(): void {
    this.sound = false;
    const music = document.getElementById("music") as HTMLAudioElement;
    if (music) {
      music.pause();
    }
  }

  activeSound(): void {
    this.sound = true;
    const music = document.getElementById("music") as HTMLAudioElement;
    if (music) {
      music.volume = 0.35;
      music.play();
    }
  }
}
