
import Vue from "vue";
import Component from "vue-class-component";
import SupplyDisplay from "@/components/SupplyDisplay.vue";
import Connect from "@/components/Connect.vue";
import RaffleInfo from "@/components/RaffleInfo.vue";
import RaffleTable from "@/components/RaffleTable.vue";
import TicketTable from "@/components/TicketTable.vue";
import {RaffleActions} from "@/store/modules/raffle/actions";
import {ApproveActions} from "@/store/modules/approve/actions";
import {MutationPayload} from "vuex";
import {ConnectorMutations} from "@/store/modules/connector/mutations";
import {Raffle} from "@/types/Interface";

@Component({
  components: {
    TicketTable,
    RaffleTable,
    RaffleInfo,
    SupplyDisplay,
    Connect,
  },
  computed: {
    displayedRaffles(): Raffle[] {
      return this.$store.state.raffle.displayedRaffles;
    },
    account(): string {
      return this.$store.state.connector.account;
    },
  }
})
export default class Tickets extends Vue {
  filter = "all";
  unsubscribe: () => void = () => null;
  mounted(): void {
    if (this.$store.state.connector.account) {
      this.$store.dispatch(RaffleActions.GET_DISPLAYED_RAFFLES);
      this.$store.dispatch(ApproveActions.GET_RAFFLE_TICKET_CONTRACT_APPROVAL);
    }
    this.unsubscribe = this.$store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === ConnectorMutations.SET_ACCOUNT && mutation.payload) {
        this.$store.dispatch(RaffleActions.GET_DISPLAYED_RAFFLES);
        this.$store.dispatch(ApproveActions.GET_RAFFLE_TICKET_CONTRACT_APPROVAL);
      }
    });
  }

  unmounted(): void {
    this.unsubscribe();
  }

  applyFilter(filter: string): void {
    this.filter = filter;
  }
}
