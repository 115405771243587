
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {Raffle} from "@/types/Interface";
import Loader from "@/components/Loader.vue";
import {ToastMutations} from "@/store/modules/toast/mutations";
import {LoaderMutation} from "@/store/modules/loader/mutations";
import {RaffleActions} from "@/store/modules/raffle/actions";
import {ApproveActions} from "@/store/modules/approve/actions";
import WinnerModal from "@/components/WinnerModal.vue";
import {MutationPayload} from "vuex";
import {ConnectorMutations} from "@/store/modules/connector/mutations";
import BuyModal from "@/components/BuyModal.vue";
import {ApproveMutations} from "@/store/modules/approve/mutations";

@Component({
  components: {BuyModal, WinnerModal, Loader},
  computed: {
    loading: function (): boolean {
      return this.$store.state.loader.loading;
    },
    raffleTicketContractApproved: function (): boolean {
      return this.$store.state.approve.raffleTicketContractApproved;
    },
    erc20ContractApproved: function (): boolean {
      return this.$store.state.approve.erc20ContractApproved;
    },
    account: function () {
      return this.$store.state.connector.account;
    },
  },
})
export default class RaffleInfo extends Vue {
  @Prop() raffle!: Raffle;
  unsubscribe: () => void = () => null;
  selected = "";
  tickets = 0;
  total = 0;
  won = false;
  currentEntries = 0;
  userMaxEntries = this.raffle.maxTicketsPerWallet;

  mounted(): void {
    this.currentEntries = this.raffle.participants.length;
    if (this.$store.state.connector.account) {
      this.init();
    }
    this.unsubscribe = this.$store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === ConnectorMutations.SET_ACCOUNT && mutation.payload) {
        this.init();
      }
    });
  }

  init(): void {
    const entries = this.raffle.participants.filter((participant) => {
      return this.$store.state.connector.account.toLowerCase() === participant.toLowerCase()
    }).length;
    this.userMaxEntries = this.userMaxEntries - entries;
    this.won = this.raffle.winners.some((winner) => {
      return this.$store.state.connector.account.toLowerCase() === winner.toLowerCase()
    });
  }

  increaseTickets(): void {
    if (this.tickets < this.userMaxEntries && this.selected === "") {
      this.tickets++;
      this.total = +(this.tickets * parseFloat(this.raffle.price)).toFixed(2);
    } else if (this.tickets < this.userMaxEntries && this.selected !== "") {
      this.tickets++;
      this.total = +(this.tickets * parseFloat(this.raffle.erc20prices[this.raffle.erc20address.indexOf(this.selected)])).toFixed(2);
    }
  }

  decreaseTickets(): void {
    if (this.tickets > 0 && this.selected === "") {
      this.tickets--;
      this.total = +(this.tickets * parseFloat(this.raffle.price)).toFixed(2);
    } else if (this.tickets > 0 && this.selected !== "") {
      this.tickets--;
      this.total = +(this.tickets * parseFloat(this.raffle.erc20prices[this.raffle.erc20address.indexOf(this.selected)])).toFixed(2);
    }
  }

  connect(): void {
    this.$modal.show("connect");
  }

  openBuyModal(): void {
    if (this.tickets === 0) {
      this.$store.commit(ToastMutations.SET_ERROR, "U need to buy at least one ticket");
      return;
    }
    this.$modal.show('buy_' + this.raffle.id);
  }

  async handleTokenSelect($event: Event): Promise<void> {
    const target = $event.target as HTMLSelectElement;
    this.$store.commit(ApproveMutations.SET_ERC20_CONTRACT_APPROVED, false);
    if (target.value) {
      this.selected = target.value;
      this.tickets = 0;
      this.total = 0;
      await this.$store.dispatch(ApproveActions.GET_ERC20_CONTRACT_APPROVAL, target.value);
    } else {
      this.selected = "";
      this.tickets = 0;
      this.total = 0;
      await this.$store.dispatch(ApproveActions.GET_RAFFLE_TICKET_CONTRACT_APPROVAL);
    }
  }

  async buy(): Promise<void> {
    this.$store.commit(LoaderMutation.SET_LOADING, true);
    let result = false;
    if (this.selected === "") {
      result = await this.$store.dispatch(RaffleActions.BUY_RAFFLE_TICKET, {
        raffleId: this.raffle.id,
        amount: this.tickets,
        mintFee: parseFloat(this.raffle.mintFee),
      });
    } else if (this.selected !== "") {
      result = await this.$store.dispatch(RaffleActions.BUY_RAFFLE_TICKET_WITH_ERC20, {
        raffleId: this.raffle.id,
        amount: this.tickets,
        mintFee: parseFloat(this.raffle.mintFee),
        index: this.raffle.erc20address.indexOf(this.selected),
      });
    }
    if (result) {
      this.currentEntries = this.currentEntries + this.tickets;
      this.userMaxEntries = this.userMaxEntries - this.tickets;
    }
    this.tickets = 0;
    this.total = 0;
  }

  async approve(): Promise<void> {
    this.$store.commit(LoaderMutation.SET_LOADING, true);
    if (this.selected) {
      await this.$store.dispatch(ApproveActions.APPROVE_ERC20_CONTRACT, this.selected);
    } else {
      await this.$store.dispatch(ApproveActions.APPROVE_RAFFLE_TICKET_CONTRACT);
    }
  }

  openModal(): void {
    this.$modal.show("winner_" + this.raffle.id);
  }
}
