
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import Raffle from "@/components/Raffle.vue";

@Component({
  components: {},
  computed: {
    displayedRaffles(): Raffle[] {
      return this.$store.state.raffle.displayedRaffles;
    },
    account(): string {
      return this.$store.state.connector.account;
    },
  }
})
export default class RaffleInfo extends Vue {
  @Prop() raffle!: boolean;

  select(filter: string): void  {
    this.$emit("selectFilter", filter);
    const elements = document.querySelectorAll("#RaffleInfo .column");
    const selected = document.getElementById(filter + "_raffle_info");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("selected");
    }
    selected?.classList.add("selected");
  }
}
