
import { Component, Vue } from "vue-property-decorator";
import { ConnectorActions } from "@/store/modules/connector/actions";

@Component
export default class ConnectModal extends Vue {
  connectMetamask(): void {
    this.$store.dispatch(ConnectorActions.CONNECT_METAMASK);
    this.$modal.hide("connect");
  }
  connectWalletConnect(): void {
    this.$store.dispatch(ConnectorActions.CONNECT_WALLET_CONNECT);
    this.$modal.hide("connect");
  }
}
